<template>
  <div class="px-4 py-5 sm:px-6">
    <CancellationFilter v-model="filters" />

    <VueSlimTable
      ref="table"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="columns"
      :per-page="perPage"
      :source="tableSource">
      <template #cell:reasons="{ value }">
        <div class="space-y-2">
          <template v-for="(val, key) in value">
            <div
              v-if="val === true"
              :key="key">
              <strong v-if="!key.includes('Issues')">{{ key }}</strong>
              <p v-if="value[`${key}Issues`] && value[`${key}Issues`].length">
                {{ value[`${key}Issues`].join(', ') }}
              </p>
            </div>
          </template>
        </div>
      </template>
      <template #cell:organizationOwner="{ value }">
        <div class="inline-flex gap-2">
          <form
            :action="`/admin/clients/${value.id}/impersonate`"
            method="POST">
            <button type="submit">
              <i class="fas fa-sign-in text-primary-600" />
            </button>
            <input
              type="hidden"
              name="authenticity_token"
              :value="csrfToken">
          </form>
          {{ value.email }}
        </div>
      </template>
      <template #cell:createdAt="{ value }">
        {{ formatDate(value) }}
      </template>
      <template #pagination>
        <UiPagination
          class="my-10"
          :page="page"
          :per-page="perPage"
          :total-count="totalCount"
          @update-page="updatePage" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import VueSlimTable from 'vue-slim-tables'
import axios from '~/init/axios'
import useTableFilters from '~/vue_widgets/composables/use_table_filters'
import { UiPagination } from '~/vue_widgets/components/ui/pagination'
import CancellationFilter from './index/filter.vue'

const columns = [
  { key: 'reasons', title: 'Reasons' },
  { key: 'organizationOwner', title: 'Owner' },
  { key: 'organization', title: 'Organization' },
  { key: 'feedback', title: 'Feedback' },
  { key: 'rank', title: 'Rank' },
  { key: 'subscriptionStatus', title: 'Status' },
  { key: 'createdAt', title: 'Created At' }
]

const table = ref(null)
const page = ref(1)
const perPage = ref(25)
const totalCount = ref(0)
const filters = ref({})
const csrfToken = axios.defaults.headers.common['X-CSRF-Token']

const { presentedFilters } = useTableFilters(table, filters)
const tableSource = (prms) => axios.get(
  '/ajax/subscriptions/cancellations',
  {
    params: {
      ...prms,
      ...presentedFilters.value,
      page: page.value,
      perPage: perPage.value
    }
  }
).then((res) => {
  totalCount.value = parseInt(res.headers['total-count'], 10)
  return res.data
})

const updatePage = (newPage, newPerPage) => {
  page.value = newPage
  perPage.value = newPerPage
  table?.value?.refetch()
}

const formatDate = (dateString) => new Date(dateString).toLocaleString()

watch(presentedFilters, () => {
  page.value = 1
}, {
  deep: true
})
</script>

// Options:
//
// Use data-pricing-active='yearly' to select yearly by default
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'frequency', 'plans']

  connect() {
    // Classes toggle on the plan switcher items
    this.activeFrequencyClass = (this.data.get('active-frequency-class') || 'bg-white shadow-sm text-black hover:text-black').split(' ')
    this.inactiveFrequencyClass = (this.data.get('inactive-frequency-class') || 'hover:text-gray-900').split(' ')

    // Classes toggle on the plans
    this.activePlansClass = (this.data.get('activePlansClass') || 'flex').split(' ')
    this.inactivePlansClass = (this.data.get('inactivePlansClass') || 'hidden').split(' ')

    // Remove any targets without plans in them
    this.frequencyTargets.forEach((target) => {
      const frequency = target.dataset.frequency
      const index = this.plansTargets.findIndex((element) => element.dataset.frequency === frequency && element.childElementCount > 0)
      if (index === -1) target.remove()
    })

    // Hide frequency toggle if we have less than 2 frequencies with plans
    if (this.frequencyTargets.length < 2) this.hideFrequencyToggle()

    const frequency = this.data.get('active') || this.frequencyTargets[0].dataset.frequency
    this.toggle(frequency)
    this.humanizePlan()
  }

  // update plan name
  humanizePlan() {
    this.plansTargets.forEach((plan) => {
      const planNames = plan.querySelectorAll('h3.mb-4.text-2xl.font-semibold')
      planNames.forEach((name) => {
        name.innerHTML = name.innerHTML.replaceAll('_', ' ')
      })
    })
  }

  // Switches visible plans
  switch(event) {
    event.preventDefault()
    this.toggle(event.target.dataset.frequency)
  }

  // Hides frequency toggle
  hideFrequencyToggle() {
    this.toggleTarget.classList.add('hidden')
  }

  // Toggles visible plans and selected frequency
  // Expects: 'monthly', 'yearly', etc
  toggle(frequency) {
    // Keep track of active frequency on a data attribute
    this.data.set('active', frequency)

    this.frequencyTargets.forEach((target) => {
      if (target.dataset.frequency === frequency) {
        this.showFrequency(target)
      } else {
        this.hideFrequency(target)
      }
    })

    this.plansTargets.forEach((target) => {
      if (target.dataset.frequency === frequency) {
        this.showPlans(target)
      } else {
        this.hidePlans(target)
      }
    })
  }

  showFrequency(element) {
    element.classList.add(...this.activeFrequencyClass)
    element.classList.remove(...this.inactiveFrequencyClass)
  }

  hideFrequency(element) {
    element.classList.remove(...this.activeFrequencyClass)
    element.classList.add(...this.inactiveFrequencyClass)
  }

  showPlans(element) {
    element.classList.add(...this.activePlansClass)
    element.classList.remove(...this.inactivePlansClass)
  }

  hidePlans(element) {
    element.classList.remove(...this.activePlansClass)
    element.classList.add(...this.inactivePlansClass)
  }
}

<template>
  <div class="grid grid-cols-4 gap-4">
    <div>
      <label>Reasons</label>
      <Multiselect
        v-model.trim="filters.reason"
        :options="reasonsOptions"
        @update:modelValue="onFilterChange" />
    </div>
    <div>
      <label>Search</label>
      <div class="relative w-full">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <input
          v-model.trim="filters.search"
          type="search"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
            focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
            dark:focus:ring-primary-500 dark:focus:border-primary-500"
          @input="onFilterChange">
      </div>
    </div>
    <div>
      <label>Date range</label>
      <UiFormDateTime
        id="date-range"
        v-model="range"
        :options="pckrOptions" />
    </div>
    <div class="flex justify-end items-end">
      <button
        type="button"
        class="btn btn-light-blue"
        @click="onClearFilter">
        Clear filters
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import { UiFormDateTime } from '~/vue_widgets/components/ui/datetime'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: { type: Object, default: () => ({}) }
})

const reasonsOptions = [
  'bug',
  'functionality',
  'support',
  'cost',
  'complicated',
  'time',
  'closed',
  'other'
]

const filters = ref(props.modelValue)
const range = ref('')

const pckrOptions = {
  mode: 'range',
  maxDate: new Date(),
  onChange: (selectedDates, dateStr, instance) => {
    if (selectedDates.length <= 1) return

    filters.value.dateRange = selectedDates.map((date) => instance.formatDate(date, instance.config.dateFormat))
  }
}

const onFilterChange = () => {
  emits('update:modelValue', filters.value)
}

const onClearFilter = () => {
  range.value = ''
  filters.value = {}
  onFilterChange()
}
</script>

<style>
  .multiselect__placeholder {
    margin-bottom: 9px;
  }
  .multiselect__tags {
    @apply border-gray-300 bg-transparent;
  }
  .multiselect__input, .multiselect__single {
    @apply bg-transparent;
  }
  .multiselect__single {
    padding: 2px 0 0 5px;
    margin-bottom: 10px;
  }
  .multiselect__input {
    padding: 0;
    margin-top: 2px;
    font-size: 14px;
  }
</style>

import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import SubscriptionsCancellationsIndex from '~/vue_widgets/subscriptions/cancellations/index.vue'

export default class extends Controller {
  connect() {
    this.vue = createApp(SubscriptionsCancellationsIndex, { subscriptionId: this.subscriptionIdValue })
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}

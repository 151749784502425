<template>
  <div
    v-if="pageCount > 1"
    class="flex justify-center relative">
    <div
      class="flex gap-[1px] shadow-[0_0_0_1px_#D1D5DB] dark:shadow-[0_0_0_1px_#4B5563]
        rounded bg-gray-300 dark:bg-gray-700 overflow-hidden">
      <button
        class="w-10 h-9 bg-white dark:bg-gray-800 dark:text-gray-300"
        type="button"
        :disabled="page === 1"
        @click="setPage(page - 1)">
        <i class="fa-regular fa-chevron-left" />
      </button>

      <template v-if="pageCount <= 5">
        <button
          v-for="n in pageCount"
          :key="n"
          :class="computeButtonClass(n)"
          type="button"
          @click="setPage(n)">
          {{ n }}
        </button>
      </template>

      <template v-else-if="page === 1">
        <button
          v-for="n in 2"
          :key="n"
          :class="computeButtonClass(n)"
          type="button"
          @click="setPage(n)">
          {{ n }}
        </button>
        <button
          :class="computeButtonClass(0)"
          type="button">
          ...
        </button>
        <button
          :class="computeButtonClass(pageCount)"
          type="button"
          @click="setPage(pageCount)">
          {{ pageCount }}
        </button>
      </template>

      <template v-else-if="page === pageCount">
        <button
          :class="computeButtonClass(1)"
          type="button"
          @click="setPage(1)">
          1
        </button>
        <button
          :class="computeButtonClass(0)"
          type="button">
          ...
        </button>
        <button class="w-10 h-9 bg-white dark:bg-gray-800 dark:text-gray-300" type="button" @click="setPage(page - 1)">
          {{ page - 1 }}
        </button>
        <button class="w-10 h-9 bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300" type="button">
          {{ page }}
        </button>
      </template>

      <template v-else>
        <template v-if="page > 2">
          <button
            :class="computeButtonClass(1)"
            type="button"
            @click="setPage(1)">
            1
          </button>
          <button
            v-if="page - 3 > 1"
            :class="computeButtonClass(0)"
            type="button">
            ...
          </button>
          <button
            v-else-if="page - 2 > 1"
            :class="computeButtonClass(page - 2)"
            type="button"
            @click="setPage(page - 2)">
            {{ page - 2 }}
          </button>
        </template>
        <button class="w-10 h-9 bg-white dark:bg-gray-800 dark:text-gray-300" type="button" @click="setPage(page - 1)">
          {{ page-1 }}
        </button>
        <button class="w-10 h-9 bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300" type="button">
          {{ page }}
        </button>
        <button class="w-10 h-9 bg-white dark:bg-gray-800 dark:text-gray-300" type="button" @click="setPage(page + 1)">
          {{ page+1 }}
        </button>
        <template v-if="pageCount - page >= 2">
          <button
            v-if="page + 3 < pageCount"
            :class="computeButtonClass(0)"
            type="button">
            ...
          </button>
          <button
            v-else-if="page + 2 < pageCount"
            :class="computeButtonClass(pageCount - 1)"
            type="button"
            @click="setPage(pageCount - 1)">
            {{ pageCount - 1 }}
          </button>
          <button
            :class="computeButtonClass(pageCount)"
            type="button"
            @click="setPage(pageCount)">
            {{ pageCount }}
          </button>
        </template>
      </template>

      <button
        class="w-10 h-9 bg-white dark:bg-gray-800 dark:text-gray-300"
        type="button"
        :disabled="page === pageCount"
        @click="setPage(page + 1)">
        <i class="fa-regular fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  page: { type: Number, default: 1 },
  perPage: { type: Number, default: 12 },
  totalCount: { type: Number, required: true }
})

const emits = defineEmits(['updatePage'])

const pageCount = ref(0)
const perPageValue = ref(props.perPage)

const setPage = (pageNum) => {
  if (props.page === pageNum) return
  emits('updatePage', pageNum, perPageValue.value)
}

const computeButtonClass = (pageNum) => {
  let classes = 'w-10 h-9'
  if (pageNum === 0) {
    classes += ' pointer-events-none'
  }
  if (props.page === pageNum) {
    return `${classes} bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300`
  }

  return `${classes} bg-white dark:bg-gray-800 dark:text-gray-300`
}

const computePageCount = () => {
  pageCount.value = Math.ceil(props.totalCount / perPageValue.value)
}

watch(() => props.totalCount, computePageCount, { immediate: true })
</script>

import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import SubscriptionCancellationShow from '~/vue_widgets/subscriptions/cancellations/show.vue'

export default class extends Controller {
  static values = {
    subscriptionId: String
  }

  connect() {
    this.vue = createApp(SubscriptionCancellationShow, { subscriptionId: this.subscriptionIdValue })
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}

import { computed, watch } from 'vue'

export default function useTableFilters(table, filters) {
  let searchTimeout
  watch(
    () => filters.value,
    () => {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(table.value?.refetch, 700)
    },
    { deep: true }
  )

  return {
    presentedFilters: computed(() => Object.fromEntries(Object.entries(filters.value)
      .map(([key, value]) => [key, typeof value === 'string' ? value?.trim() : value])
      .filter((entry) => entry[1])))
  }
}

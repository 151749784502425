<template>
  <input
    ref="input"
    type="text"
    :value="modelValue"
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
      focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
      dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    @input="$emit('update:modelValue', $event.target.value)">
</template>

<script setup>
import 'flatpickr/dist/flatpickr.min.css'
import { ref, onBeforeUnmount, onMounted } from 'vue'

import flatpickr from 'flatpickr'

const props = defineProps({
  modelValue: {
    required: true,
    validator: (prop) => typeof prop === 'string' || prop === null
  },
  options: {
    type: Object,
    required: true
  }
})

defineEmits(['update:modelValue'])

const input = ref(null)
let pckr

onMounted(() => {
  if (input.value) {
    pckr = flatpickr(input.value, props.options)
  }
})

onBeforeUnmount(() => {
  pckr?.destroy()
})
</script>
